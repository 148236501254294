'use client';

import Link from 'next/link';

import { FlexCenter } from '@/components';
import { LANGUAGE_TAG, ROUTES } from '@/config';
import translations from '@/contexts/translations-context/translations';

/**
 * The component is used to specifically handle errors in the root layout of the application so it has its own <html> and <body> tags.
 * It is more simple than the other error pages as this is a last resort at rendering something.
 * @see https://nextjs.org/docs/app/api-reference/file-conventions/error#global-errorjs
 */
const GlobalError: React.FC = () => {
  const texts = translations.components.errorPage;

  return (
    <html lang={LANGUAGE_TAG}>
      <body>
        <FlexCenter>
          {texts.defaultMessage}
          <Link href={ROUTES.AUTH.SIGNIN}>{texts.button}</Link>
        </FlexCenter>
      </body>
    </html>
  );
};

export default GlobalError;
